#employee-home-page {
  max-width: 1000px;
  margin: 0 auto;
  padding: 50px;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
  color: $sd-dark-gray-blue;
}

.employee-home-header {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.employee-home-header-description {
  color: $sd-dark-gray;
  font-style: italic;
}

.employee-home-header-disclaimer {
  color: $red3;
  font-weight: bold;
}

.employee-home-action-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 50px;
  row-gap: 50px;
}

.employee-home-action-grid-item {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  border: 1px solid lightgray;
  height: 300px;
  padding: 25px;
  background-color: white;
  transition: transform 250ms;
}

.employee-home-action-grid-item:hover {
  border-color: $sd-green;
  cursor: pointer;
  transform: translateY(-10px);
}

.employee-home-action-grid-item-title {
  text-align: center;
  font-size: 1.5rem;
  padding: 20px 0;
}

.employee-home-action-grid-item-icon-container {
  display: flex;
  column-gap: 1rem;
  color: $sd-light-blue;
}

.employee-home-action-grid-item-description {
  padding: 20px 0;
  color: $sd-dark-gray;
}
