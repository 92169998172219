.launchpad-dashboard {
  padding: 0 10%;
  overflow: auto;

  &__connection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem;
  }

  &__connection-selector {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin: 1rem 0;
    border: 1px solid $sd-light-gray;
    border-radius: 5px;
    padding: 10px 15px;
  }

  &__add-buttons {
    display: flex;
    gap: 1rem;
  }

  &__connection-details {
    padding: 1.5rem;
    width: 100%;

    &__header {
      display: flex;
      align-items: baseline;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__row {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &__error-callout {
      margin: 1.5rem 0 !important;
    }

    &__buttons {
      display: flex;
      gap: 10px;
      margin: 1rem 0;
    }
  }
}

.sso-connection-dialog {
  &__overview {
    margin: 1rem 0;
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__help {
      margin-bottom: 1rem;
    }

    &__row {
      margin-bottom: 1rem;
    }

    &__buttons {
      display: flex;
      justify-content: center;
    }
  }
}

.sd-landing-new-user-launchpad {
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 20px;

  &-actions {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    .divider {
      margin-top: 20px;
      height: 100%;
      width: 0;
      border-left: 1px solid $light-gray1;
    }

    .new-connection-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20px;
      padding: 20px 0px;
    }
  }
}
