.page {
  display: flex;
  margin: 5%;
  flex-direction: column;
}

.search {
  margin-bottom: 1rem;

  display: flex;
  gap: 10px;
  align-items: center;

  .#{$ns}-input-group {
    flex: 1;
  }
}

.search-filters,
.search-filters-tooltip {
  .#{$ns}-checkbox {
    margin: 0;

    & input:indeterminate ~ .#{$ns}-control-indicator::before {
      background-color: $gray3;
      border-radius: 2px;
    }
  }
}

.rdt_TableHead > div {
  font-weight: 700;
}
