.sd-popover-menu {
  width: 200px;

  .#{$ns}-menu {
    max-height: 300px;
    overflow: auto;
  }
}

.#{$ns}-tooltip.#{$ns}-dark .#{$ns}-heading {
  color: $pt-text-color;
}
