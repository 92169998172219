.dashboard {
  height: 100%;
}

.dashboard-description {
  margin-bottom: 0px;
  line-height: 1.2em;
  letter-spacing: -0.015em;
  font-weight: 500;
  font-size: 1.25em;
}

.dashboard-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
  }

  .product-card {
    position: relative;

    &:hover {
      background-color: $light-gray4;

      .product-card-icon {
        display: block;
      }
    }

    .product-card-icon {
      display: none;
      position: absolute;
      top: 15px;
      right: 15px;
    }

    .product {
      img {
        margin-bottom: 5px;
        max-width: 90%;
      }
    }
  }
}

.dashboard-widgets {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  .widget-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
    height: 100%;
    text-align: center;
    .widget-icon {
      width: 45px;
      height: 45px;
    }
  }
}

.server-overview {
  padding: 30px 40px;

  &-header {
    display: flex;
    justify-content: space-between;
  }

  .metrics {
    .metric {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      &.metric-sub-section {
        font-size: 1.3em;
        margin-top: 5px;
      }

      .metric-label {
        font-size: 1.1em;
        vertical-align: sub;
      }

      .metric-value {
        font-size: 1.5em;
        color: $sd-vdark-blue;
      }
    }
  }
}

.dashboard-quick-actions {
  padding: 30px 40px;

  .action-row {
    margin-top: 20px;
    font-size: 1.1em;
  }
}

.dev-dashboard {
  width: 100%;
}

.dev-help {
  padding: 20px;
  display: flex;
  flex-direction: column;

  .code-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    .code-controls {
      pre {
        font-size: 0.6875em;
      }

      .label {
        align-self: center;
        margin-right: 10px;
      }
    }
  }
}

.connection-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .connection-heading {
    color: white;
    font-weight: bolder;
    font-size: 2.5em;
    line-height: 1.2em;
    letter-spacing: -0.015em;
  }
}

.sd-header-right-btn {
  float: right;

  &.#{$ns}-button.#{$ns}-minimal .#{$ns}-icon {
    color: white;
  }
}

.share-dialog-add-user-button {
  margin-top: 5px;
}
