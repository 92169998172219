.sd-voicebox-chat-list-aside {
  .vb-navbar-aside-indicator {
    display: flex;
    flex-direction: column;
  }

  .vb-navbar-aside-header,
  .vb-navbar-aside-content,
  .vb-navbar-aside-divider {
    flex: none;
  }

  .sd-voicebox-chat-list-items {
    flex: 1 1 auto;
    padding-right: 18px;
    overflow-y: auto;
    scrollbar-gutter: stable;

    display: flex;
    flex-direction: column;
    gap: $vb-space-8px;
  }

  .sd-voicebox-chat-list-item {
    cursor: pointer;
    width: 100%;
    border-radius: $vb-border-radius-sm;
    padding: $vb-space-4px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $vb-space-8px;

    box-shadow: none;
    &.#{$ns}-active,
    &:active,
    &:hover,
    &:focus-visible {
      box-shadow: none;
    }

    background: none;
    border: $vb-border-width-thick solid transparent;

    &.sd-voicebox-chat-list-item-divider {
      padding-left: 10px;
      display: flex;
      justify-content: flex-start;

      .sd-voicebox-chat-list-item-icon {
        color: $sd-orange;
      }
    }

    &:active,
    &.sd-voicebox-chat-list-item--active {
      background: $vb-neutral-background-transparent-background-transparent-strong;
      border: $vb-border-width-thick solid transparent;
    }

    &:hover {
      @include vb-interactive-badge-hover();
      background: $vb-neutral-background-transparent-background-transparent-weaker;
      border: $vb-border-width-thick solid transparent;
    }

    &:focus-visible {
      @include vb-focus();
      border: $vb-border-width-thick solid $vb-color-white;
    }
  }

  .sd-voicebox-chat-list-item-text {
    flex-grow: 1;
    min-width: 0;
    padding-left: $vb-space-8px;

    .vb-typography {
      margin-top: 1px;
    }
  }

  .sd-voicebox-chat-list-item-actions {
    flex: none;

    display: flex;
    align-items: center;
    gap: $vb-space-8px;
  }
}
