.page-heading {
  color: $white;
  line-height: 1.2em;
  letter-spacing: -0.015em;
  font-weight: bolder;
  font-size: 2.5em;
  margin-bottom: 10px;
}

.page-overview {
  color: $white;
  line-height: 1.2em;
  letter-spacing: -0.015em;
  font-weight: bold;
  font-size: 1.5em;
}

.page-text {
  color: $white;
  line-height: 1.2em;
  letter-spacing: -0.015em;
  font-size: 1.25em;
}

.mp-home {
  height: 98%;
  display: flex;
  flex-direction: column;

  .mp-title-bar {
    padding: 20px 20px 10px 20px;
    background-color: #00212c;
  }

  .mp-menu-bar {
    padding: 10px 20px 10px 20px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    column-gap: 20px;

    background-color: #eff5f9;

    &-tag-selector .#{$ns}-input {
      min-width: 200px;
    }

    .#{$ns}-switch {
      margin-bottom: 0px;
      align-self: center;
      margin-right: 0px;
    }
  }
}

.mp-spinner {
  margin-top: 45vh;
}

.kits-section {
  padding: 20px;

  .kits-load-more {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

.kits-list {
  /**
  * The goal of the following is to make the grid auto-fit with a max column count.
  * (This sorcery was stolen from https://css-tricks.com/an-auto-filling-css-grid-with-max-columns/)
  *
  * Input values:
  */
  --kits-layout-gap: 15px;
  --kits-max-column-count: 4;
  --kits-item--min-width: 300px;

  /**
  * Calculated values:
  */
  --kits-gap-count: calc(var(--kits-max-column-count) - 1);
  --kits-total-gap-width: calc(var(--kits-gap-count) * var(--kits-layout-gap));
  --kits-item--max-width: calc(
    (100% - var(--kits-total-gap-width)) / var(--kits-max-column-count)
  );
  --kits-item--max-of-widths: max(
    var(--kits-item--min-width),
    var(--kits-item--max-width)
  );

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--kits-item--max-of-widths), 1fr)
  );
  grid-gap: var(--kits-layout-gap);
}

.kits-card-selected {
  background-color: $sd-orange;
}

.kits-card {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-top {
    display: flex;
    flex-direction: column;
    flex: auto;
  }

  .kits-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .kits-name {
      color: $sd-dark-gray-blue;
      font-size: 1.2em;
      flex-grow: 1;
    }

    .kits-menu {
      display: flex;
      margin-left: auto;
      justify-content: flex-end;
    }

    .kits-icon {
      align-self: start;
    }
  }

  .kits-description {
    flex: auto;
    color: $sd-dark-gray-blue;
    font-size: 1.1em;
  }

  .kits-tags {
    min-height: 20px;
    display: flex;
    gap: 5px;
  }
}

.kit-title {
  background-color: $sd-light-gray-blue;
  color: $sd-dark-gray-blue;
  font-size: 2em;
  padding: 20px;
  margin-bottom: 0px;
}

.kit-description {
  color: $sd-dark-gray-blue;
  font-size: 1.5em;
  padding: 20px;
  background-color: #eff5f9;
  margin: 0px;
}

.kit-overview {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}

.kit-install {
  .kit-install-label {
    margin: 0;
  }

  .kit-install-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
  }

  .kit-install-buttons {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 10px;
  }

  .#{$ns}-html-select select {
    min-width: 140px;
  }

  .database-selector .#{$ns}-button-text {
    min-width: 165px;
  }
}

.database-selector-menu {
  max-height: 300px;
  max-width: 300px;
  overflow: auto;
}

.create-database-tooltip {
  width: 100%;
}

.overview-panel {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .overview-panel-main {
    padding-bottom: 5%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    column-gap: 20px;

    .overview-panel-content {
      padding-left: 20px;
    }
  }

  .code-block-container {
    display: flex;
    flex-direction: column;
    padding: 25px;
    border: 1px solid $light-gray4;
    row-gap: 10px;
  }

  .copy-to-clipboard-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .readme-img-container {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 900px;
  }

  .readme-img {
    max-width: 100%;
    max-height: 100%;
  }

  .overview-panel-content {
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $sd-dark-gray-blue;
    }

    code {
      font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: 1rem;
    }

    th {
      border: 1px solid $sd-dark-gray-blue;
      background-color: $sd-dark-gray-blue;
      color: $white;
    }

    td,
    th {
      padding: 8px;
    }

    td {
      border: 1px solid $light-gray4;
    }

    tr:nth-child(even) {
      background-color: $light-gray4;
    }
  }

  .overview-panel-content-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .overview-panel-metadata-container {
    display: flex;
    flex-direction: row;
    min-width: 350px;
  }

  .divider {
    margin-top: 20px;
    height: 100%;
    width: 0;
    border-left: 1px solid $light-gray4;
  }

  .overview-panel-metadata {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .overview-panel-metadata-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }

  .title {
    color: $sd-dark-gray-blue;
    font-size: 2em;
    font-weight: bolder;
    margin: 0;
  }

  .related-list {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: flex-start;
  }

  .related-list > button {
    text-align: left;
  }

  .sidebar-title {
    color: $sd-dark-gray-blue;
    font-size: 2em;
    font-weight: bolder;
    margin-bottom: 10px;
  }

  .metadata-title {
    color: $sd-dark-gray-blue;
    line-height: 1.5em;
    letter-spacing: -0.015em;
    font-weight: bolder;
  }

  .metadata-actions-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }

  .metadata-table {
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 3px;
    column-gap: 10px;

    .metadata-table-property {
      justify-self: flex-start;
      white-space: nowrap;
    }

    .metadata-table-value {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      font-weight: bold;
      align-self: flex-end;
    }
  }
}

.get-started-db {
  margin-top: 20px;
  column-gap: 20px;
  display: flex;
  align-items: baseline;
  flex-direction: column;
}

.gs-data-input {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-top: 20px;

  .#{$ns}-form-group {
    margin: 0px;
  }
}

.title-bar {
  .title {
    color: white;
    font-size: 2.5em;
    font-weight: bolder;
    line-height: 1.2em;
    letter-spacing: -0.015em;
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;

  .menu {
    margin-left: auto;
  }
}

.cta-button {
  background-color: $sd-orange;
  background-image: none;
  box-shadow: none;
  font-weight: bold;
  color: $white;
}

.cta-button:hover,
.cta-button:active {
  background-color: $sd-orange;
  background-image: none;
  box-shadow: inset(0, 0, 0, 5px, rgba(16, 22, 26, 0.3)),
    inset(0, -5px, 0, rgba(16, 22, 26, 0.3));
  color: $white;
  font-weight: bold;
}
