.recommended {
  border: $sd-medium-blue solid 3px;
}

.overview {
  color: $sd-dark-gray-blue;
  line-height: 1.2em;
  letter-spacing: -0.015em;
  font-weight: 500;
  font-size: 1.5em;
  text-align: left;
  padding-left: 20px;
  padding-top: 20px;
}

.get-started-summary {
  text-align: center;
}

.get-started {
  width: 100%;
  text-align: center;
  padding: 30px;

  &__products {
    column-gap: 30px;
    row-gap: 30px;
    margin: 20px;

    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 900px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__product-card {
    border-radius: 10px;
    color: $sd-dark-gray;
    display: flex;
    flex-direction: column;
    max-width: 350px;
    min-height: 30em;
  }

  &__product-card-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    text-align: left;
  }

  &__product-card-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  .name {
    line-height: 1.2em;
    letter-spacing: -0.015em;
    color: $sd-orange;
    font-size: 2em;
    margin: 0px;
  }

  .price {
    color: $sd-medium-blue;
    font-size: 2em;
    padding-bottom: 20px;
  }

  &__product-card-header-recommended {
    color: $white;
    background-color: $sd-medium-blue;
    border-radius: 15px;
    font-size: 75%;
    padding: 5px 10px;
  }

  &__product-card-content {
    height: 100%;
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    text-align: left;

    h6 {
      color: $sd-dark-gray-blue;
      font-weight: bold;
      font-size: 1.125em;
    }

    p {
      font-size: 1.25em;
      min-height: 28%;
    }
  }

  &__product-card-content-list {
    padding-inline-start: 0;
    list-style: none;
    margin-block-end: 1em;
  }

  &__product-card-content-list-item-icon {
    color: $sd-medium-blue;
    margin-right: 1em;
  }

  &__product-card-footer {
    bottom: 0px;
    margin-top: 20px;

    a.#{$ns}-button {
      min-width: 165px;
      padding-inline: 20px;
    }
  }

  &__select-plan {
    margin-bottom: 4em;
  }

  &__plan-details {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 4em 0;
  }
}

.sd-cart-drawer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sd-cart-drawer-content {
  display: flex;
  flex-direction: column;
  // overflow-y: scroll;

  &__card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 150px;
    // padding-right: 0;
  }

  &__card-header {
    display: flex;
    justify-content: space-between;
  }
  &__checkout-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.sd-cart-drawer-footer {
  height: auto;
  width: 100%;
  border-top: 1px outset $sd-dark-gray;
  margin: 1em 0;
  padding: 1em;
}

.feature-table {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  flex-grow: 2;

  h6 {
    color: $sd-dark-gray-blue;
    font-weight: bold;
    font-size: 1.125em;
    line-height: 1.2em;
    letter-spacing: -0.015em;
  }

  .feature-table-row {
    display: flex;
    flex-direction: row;
    column-gap: 10px;

    font-size: 0.875em;
    line-height: 1.2em;
    letter-spacing: -0.015em;
  }

  .#{$ns}-icon {
    color: $sd-medium-blue;
  }
}

.comparison-table {
  width: 60%;

  tr th {
    text-align: center;
    background-color: $sd-medium-blue;
    color: $white;
    min-width: 250px;
  }
  thead tr th:first-child {
    background-color: transparent;
  }
  tr td {
    text-align: center;
  }
  td:first-child {
    background-color: #f8fbfc;
    font-weight: bold;
    color: $sd-medium-blue;
  }
}
