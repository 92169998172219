.sd-dialog-upgrade-plan {
  background: white;
  height: 90vh;
  min-height: 700px;
  max-height: 1100px;
  min-width: 700px;
  max-width: 1050px;
  width: 85vw;

  &__header {
    display: flex;
    flex-direction: row-reverse;
  }

  &__body {
    margin: 0;
    padding: 0 15px 0 15px;
    overflow: scroll;

    &-products {
      column-gap: 30px;
      row-gap: 30px;
      margin: 20px;

      display: flex;
      flex-direction: row;
      justify-content: center;

      @media (max-width: 900px) {
        flex-direction: column;
        align-items: center;
      }

      .get-started__product-card-footer {
        text-align: center;
      }

      .name {
        line-height: 1.2em;
        letter-spacing: -0.015em;
        color: $sd-orange;
        font-size: 2em;
        margin: 0px;
      }

      .price {
        color: $sd-medium-blue;
        font-size: 2em;
        padding-bottom: 20px;
      }
    }

    &-comparison {
      width: 100%;

      .comparison-table {
        width: 100%;

        tr th {
          min-width: 175px;
          max-width: 250px;
        }
      }
    }

    .#{$ns}-heading {
      text-align: center;
    }

    > h3 {
      margin-bottom: 20px;
      margin-top: 20px;
    }

    > p {
      font-size: 16px;
      margin-top: 25px;
      text-align: center;
    }
  }
}
