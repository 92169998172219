.sd-databricks {
  .sd-landing-grid {
    row-gap: 40px;
  }
}

.databricks-connection-input {
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 20px;

  .#{$ns}-form-group {
    margin: 0px;
  }
}

.pc-container {
  margin-top: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(50vh - 80px);
  font-size: 18;
  font-weight: bold;
}

.row-disabled {
  background-color: rgba(135, 141, 143, 0.5);
  pointer-events: none;
  width: 100%;
}

.sd-partner-dialog-connection {
  width: 300px;

  .sd-partner-form__body {
    min-height: 200px;
  }
}

.sd-partner-dialog-profile {
  min-width: 250px;
  max-width: 450px;
  form {
    padding: 15px;
  }
  .inline {
    display: grid;
    justify-content: center;
    column-gap: 15px;
    row-gap: 0;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

.sd-partner-video-card {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;
  background-color: $light-gray5;

  img {
    height: 180px;
  }
}
