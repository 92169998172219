@font-face {
  font-family: 'Inter';
  src: url('~vet-bones/design-system/assets/inter.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  src: url('~vet-bones/design-system/assets/inter-italic.ttf')
    format('truetype');
}

// 1. shared colors and colors
@import 'src/ui/templates/styles/colors.scss';
@import 'vet-bones/styles/colors.scss';

// 2. helpers
@import 'vet-bones/styles/helpers.scss';

// 3. blueprint variables
@import '@blueprintjs/core/lib/scss/variables.scss';

// 4. app variables
@import 'src/ui/templates/styles/variables.scss';
@import 'vet-bones/styles/variable-defaults.scss';

// 5. app intents
@import 'vet-bones/styles/intent-examples.scss';

// 6. blueprint core styles
@import '@blueprintjs/core/src/blueprint.scss';
@import '@blueprintjs/datetime/src/blueprint-datetime.scss';
@import '@blueprintjs/datetime2/src/blueprint-datetime2.scss';
@import '@blueprintjs/popover2/src/blueprint-popover2.scss';
@import '@blueprintjs/table/src/table.scss';

// 7. app overrides + component styles
@import 'vet-bones/styles/overrides.scss';
@import 'vet-bones/styles/components/data-catalog.scss';
@import 'vet-bones/styles/components/generic-properties-editor.scss';
@import 'vet-bones/styles/components/query-builder.scss';
@import 'vet-bones/styles/components/query-builder-read-only.scss';
@import 'vet-bones/styles/components/select.scss';
@import 'src/ui/templates/styles/overrides.scss';

// 8. design system styles + overrides
@import 'vet-bones/design-system/styles/index.scss';
@import 'vet-bones/styles/components/navbar/index.scss';
@import 'vet-bones/styles/components/voicebox/index.scss';
@import 'vet-bones/styles/components/portal/portal-endpoint-aside.scss';

// 9. application styles that must be defined after templates and blueprint
@import 'src/ui/styles/components.scss';
@import 'src/ui/styles/layout.scss';
@import 'src/ui/styles/containers/internal/home.scss';
@import 'src/ui/styles/containers/internal/user-dashboard.scss';
@import 'src/ui/styles/containers/internal/user-search.scss';
@import 'src/ui/styles/containers/aside.scss';
@import 'src/ui/styles/containers/dashboard.scss';
@import 'src/ui/styles/containers/diagnostic.scss';
@import 'src/ui/styles/containers/get-started.scss';
@import 'src/ui/styles/containers/landing.scss';
@import 'src/ui/styles/containers/marketplace.scss';
@import 'src/ui/styles/containers/partner.scss';
@import 'src/ui/styles/containers/upgrade-plan.scss';
@import 'src/ui/styles/containers/delete-account.scss';
@import 'src/ui/styles/containers/cancel-cloud.scss';
@import 'src/ui/styles/containers/invitation.scss';
@import 'src/ui/styles/containers/launchpad/dashboard.scss';
