.sd-diagnostic {
  padding: 20px;
  overflow-x: auto;
  width: 100%;
  table {
    padding-bottom: 15px;
    overflow: auto;
    tr td:first-child {
      width: 300px;
    }
  }
  .retry {
    float: right;
  }

  .#{$ns}-card {
    overflow-x: auto;
  }

  .sd-diagnostic-voicebox {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 12px;

    &__top-bar {
      display: flex;
      justify-content: space-between;

      &__selectors {
        display: flex;
        flex-direction: row;
        gap: 6px;

        .sd-selector {
          .sd-selector-button-group {
            min-width: 200px;
            max-width: 300px;

            .sd-selector-button {
              flex: 1 0 auto;

              .#{$ns}-button-text {
                flex: 1;
                text-align: start;
              }
            }
          }
        }
      }

      &__submit {
        display: flex;
        flex-direction: row;
        gap: 6px;
      }
    }

    &__results {
      display: flex;
      flex-direction: column;
      gap: 6px;

      &__callout {
        ul {
          margin: 0;
          padding-left: 30px;
        }
      }
    }
  }
}

#diagnostic-report {
  display: flex;
  column-gap: 20px;
}

.diagnostic-check {
  display: flex;
  column-gap: 10px;
  padding-bottom: 10px;
  width: 450px;
  min-height: 38px;
}
.diagnostic-message {
  display: flex;
  column-gap: 10px;
  padding: 0 0 20px 0;
  width: 100%;
}
