html,
#app-main {
  -webkit-user-select: text;
  -webkit-user-drag: text;
  -moz-user-select: text;
  height: 100vh;
  cursor: default;
  user-select: text;
  -webkit-font-smoothing: antialiased;

  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

body {
  min-height: 100vh;
}

main {
  display: flex;
  flex-direction: column;
  height: 100%;

  > .sd-horizontal {
    flex: 1;
  }

  .sd-app-body {
    flex: 1;

    > .sd-vertical {
      height: 100%;
    }
  }
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  background-color: $sd-dark-blue;
  color: $sd-light-gray-blue;
  height: $sd-footer-height;

  p {
    margin: auto 1em;
  }
}

.sd-horizontal {
  display: flex;
  flex-direction: row;
  min-width: 0;
  min-height: 0;
}

.sd-vertical {
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
}

.sd-app-body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.sd-page-summary {
  display: flex;
  flex-direction: column;
  background-color: $sd-vdark-blue;
  background-image: url('../templates/images/outro-bg.svg');
  background-position-y: top;
  background-position-x: right;
  background-repeat: no-repeat;
  padding: 20px;
  row-gap: 10px;

  h2 {
    color: $white;
    line-height: 1.2em;
    letter-spacing: -0.015em;
    font-weight: bolder;
    font-size: 2.5em;
    margin: 0px;
  }

  p {
    color: $white;
    line-height: 1.2em;
    letter-spacing: -0.015em;
    font-weight: 500;
    font-size: 1.25em;
    margin: 0px;

    a,
    button {
      color: $sd-orange;
      font-weight: bold;
    }
  }
}

.sd-page-content {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  background-color: $sd-light-gray;

  h3 {
    text-align: center;
    color: $sd-dark-gray-blue;
    font-size: 2.5em;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: -0.015em;
  }
}

.sd-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.sd-gutter {
  padding: 20px;
}

.sd-warning {
  background-color: $pt-intent-warning;
  color: $white;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  font-weight: 500;
  justify-content: center;
  min-height: 40px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  border: none;
  width: 100%;

  &:hover {
    color: $white;
    text-decoration: none;
  }

  &__clickable:hover {
    cursor: pointer;
  }
}

.sd-row {
  padding: 20px;
  display: flex;
  justify-content: space-around;
  column-gap: 15px;
  align-items: center;
  .#{$ns}-card {
    width: 300px;
  }
}

.sd-list {
  &__collection {
    padding: 20px;
  }
}

.manage-connections-container {
  padding: 50px;
  display: flex;
}

.manage-api-tokens-container {
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin: 50px;
  min-width: min(800px, calc(100vw - 40px));
  max-width: 1200px;

  .sd-voicebox-app-card {
    margin-bottom: 25px;

    &-config {
      display: grid;
      grid-template-columns: repeat(2, minmax(150px, 50%));
      gap: 5px;

      &--connection {
        grid-column: span 2;
      }

      strong {
        margin-right: 10px;
      }

      p {
        word-break: break-word;
      }
    }

    .divider {
      margin-bottom: 5px;
    }
  }

  .sd-table h6 {
    align-self: flex-end;
    margin: 0;
  }

  .sd-api-token-name {
    width: 25vw;
  }

  .#{$ns}-html-table {
    padding-top: 0;
  }
}

.no-connection-container {
  width: 100%;
}

.divider {
  width: 100%;
}
.sd-table {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    column-gap: 15px;
  }

  &__actions {
    display: block;

    &__button-container {
      display: inline-flex;
      column-gap: 10px;
    }
  }

  h2 {
    margin: 0;
  }

  table {
    padding: 20px 9px;
    width: 100%;

    td {
      vertical-align: middle;
    }

    strong {
      color: $sd-medium-blue;
    }
  }
}

.#{$ns}-menu {
  .sd-indent-menu {
    div {
      padding-left: 1em;
    }
  }
}

a.sd-link-undecorated {
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

$navbar-wrapper-body-base-padding: 50px;
$navbar-wrapper-body-collapsed-offset: calc((240px - 68px) / 2);

.vb-navbar-wrapper {
  .vb-navbar--expanded + .vb-navbar-wrapper-body {
    // shared
    .sd-page-summary,
    .welcome-container,
    // landing
    .sd-landing-dashboard,
    .sd-landing-new-user,
    .sd-landing-new-user-launchpad,
    // get started
    .get-started,
    // connections
    .manage-connections-container,
    // databricks
    .sd-landing-grid,
    // kits
    .mp-menu-bar,
    .all-kits,
    .overview-panel {
      padding-left: $navbar-wrapper-body-base-padding;
      padding-right: $navbar-wrapper-body-base-padding;
      transition: padding ease-out 300ms;
    }

    .welcome-container {
      padding-right: max($navbar-wrapper-body-base-padding, 90px);
    }

    .overview-panel {
      padding-right: 0;
    }
  }

  .vb-navbar--collapsed + .vb-navbar-wrapper-body {
    // shared
    .sd-page-summary,
    .welcome-container,
    // landing
    .sd-landing-dashboard,
    .sd-landing-new-user,
    .sd-landing-new-user-launchpad,
    // get started
    .get-started,
    // connections
    .manage-connections-container,
    // databricks
    .sd-landing-grid,
    // kits
    .mp-menu-bar,
    .all-kits,
    .overview-panel {
      padding-left: $navbar-wrapper-body-base-padding +
        $navbar-wrapper-body-collapsed-offset;
      padding-right: $navbar-wrapper-body-base-padding +
        $navbar-wrapper-body-collapsed-offset;
      transition: padding ease-in 300ms;
    }

    .sd-page-summary {
      padding-left: $navbar-wrapper-body-base-padding +
        $navbar-wrapper-body-collapsed-offset;
      padding-right: $navbar-wrapper-body-base-padding;
    }

    .welcome-container {
      padding-right: max(
        $navbar-wrapper-body-base-padding +
          $navbar-wrapper-body-collapsed-offset,
        90px
      );
    }

    .overview-panel {
      padding-right: 0;
    }
  }
}

.vb-dropdown-popover,
.vb-menu-popover,
.vb-modal,
.vb-navbar,
.vb-navbar-aside,
.vb-navbar-menu-popover,
.vb-tooltip,
.vb-voicebox-page {
  :focus {
    outline: none;
  }
}
