.sd-dialog-cancel-cloud {
  min-width: 450px;
  min-height: 350px;
  &__body {
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__input_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
